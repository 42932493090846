@import '../../styles/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  text-align: center;
  color: var(--color-text-light);
}
