.trackWrapper {
  overflow: hidden;
  display: flex;
  flex: 1;
  margin: var(--size-spacing-02);
}

.track {
  width: 100%;
  display: flex;
  transition: transform 250ms ease-in-out;
}

.tileWrapper {
  align-items: center;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  z-index: 0;
}
