.averageDonationBanner {
    background-color: var(--color-neutral-light);
    border-radius: 3px;
    margin-top: var(--size-spacing-01);
    margin-bottom: var(--size-spacing-01);
    padding-top: var(--size-spacing-03);
    padding-bottom: var(--size-spacing-03);
}

.donateIcon {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    width: 40px;
    margin-left: var(--size-spacing-02);
    margin-top: -2px;
    margin-right: var(--size-spacing-03);
}

.averageDonationAmount {
    color: var(--color-purple-500);
    font-weight: bold;
}

.giveNow {
    color: var(--color-blue-base);
    text-decoration: none;
}