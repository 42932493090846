.tile {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.description {
  display: flex;
  flex: 1 0 60px;
  justify-content: center;
  align-items: center;
}

.amount {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: var(--size-spacing-02);
  flex-wrap: wrap;
}

.type {
  margin: 0;
}
