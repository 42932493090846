@import '../../styles/breakpoints';

.modal {
  @include device-small {
    max-width: 591px;
  }
}

.modalFooter {
  display: flex;
  justify-content: center;
  margin-top: var(--size-spacing-05);
}
