@import '../../styles/breakpoints';


.modal {
  padding: var(--size-spacing-07) var(--size-spacing-09);
  text-align: center;

  @include device-large {
    max-width: 600px;
  }
}

.image {
  margin-bottom: var(--size-spacing-04);
}

.message {
  margin-bottom: var(--size-spacing-06);
}

.button {
  max-width: 360px;
  display: block;
  margin: 0 auto var(--size-spacing-04);
}
