.panel {
  position: fixed;
  right: 32px;
  bottom: 32px;
  background-color: #fff;
  border: 1px #000 solid;
  padding: 12px;
  display: flex;
  column-gap: 8px;
  z-index: 10000;
}
