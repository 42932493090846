.causeList {
  display: flex;
  gap: var(--size-spacing-05);
  flex-wrap: wrap;
}

.logo {
  width: 72px;
  height: 72px;
}
