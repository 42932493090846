@import '../../styles/breakpoints';

.banner {
  padding: var(--size-spacing-03) var(--size-spacing-04);
  display: flex;
}

.gradient {
  background: linear-gradient(
    90deg,
    rgba(43, 109, 238, 1) 0%,
    rgba(149, 43, 238, 1) 100%
  ); // Colours not in carepack

  color: var(--color-neutral-lighter);

  a {
    color: var(--color-neutral-lighter);
  }
}

.info {
  background-color: var(--color-interaction-lighter);
}

.content {
  max-width: var(--brand-container-width, var(--container-width));
  margin-left: auto;
  margin-right: auto;
  font-size: var(--font-size-01);
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  width: 20px;
  height: 20px;

  svg {
    fill: var(--color-text-light);
  }
}
