@import '../../styles/breakpoints';

.videoContainer {
  position: relative;
}

.video {
  display: block;
  height: 300px;

  @include device-large {
    height: 412px;
  }
}

.chatToggle {
  display: block;
  position: absolute;
  right: var(--size-spacing-04);
  bottom: var(--size-spacing-04);
  background-color: rgba($color: #000, $alpha: 0.75);

  padding: var(--size-spacing-03);
  border-radius: 5px;

  :global(.cp-toggle) {
    margin: 0;
    color: var(--color-white);
  }
}
