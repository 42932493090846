.badge {
  border-radius: 4px;
  padding: var(--size-spacing-01);
  font-size: var(--font-size-01);
  font-weight: 600; // not in carepack
}

.purple {
  background-color: var(--color-purple-300);
  color: var(--color-purple-500);
}

.blue {
  background-color: var(--color-blue-300);
  color: var(--color-blue-500);
}

.red {
  background-color: var(--color-red-300);
  color: var(--color-red-500);
}

.orange {
  background-color: var(--color-orange-300);
  color: var(--color-orange-500);
}

.green {
  background-color: var(--color-green-300);
  color: var(--color-green-500);
}
