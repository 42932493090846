.box {
  border: 1px solid var(--color-neutral-600);
  margin: var(--size-spacing-03);
  padding: var(--size-spacing-03);
  display: flex;
  flex-direction: column;
}

.container {
  list-style: none;
  display: flex;
  gap: var(--size-spacing-03);
  align-items: center;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 3.2rem;
  aspect-ratio: 1;
  cursor: pointer;

  &:focus {
    border: none;
  }
}

.arrowSvg {
  fill: var(--color-neutral-lighter);
}

.impactContainer {
  text-align: center;
  flex: 0 1 20%;
}

.carousel {
  &:focus-visible {
    outline: none;
  }
}
