$waveDuration: 0.7s;
$waveCount: 4;

.totaliser {
  min-width: 64px;
}

.svgContainer {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
}

.border {
  fill: var(--color-brand-base);
  stroke: var(--color-brand-darker);

  &.light {
    stroke: var(--color-grey-300);
  }
}

.background {
  fill: var(--color-brand-darker);
  stroke: var(--color-brand-base);

  &.light {
    stroke: var(--color-grey-300);
  }
}

@keyframes waveAction {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-116px, 0, 0);
  }
}

@keyframes waveEnd {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-174px, 0, 0);
  }
}

.wave {
  fill: var(--color-brand-base);
}

.waveAnimated {
  transform: translate3d(0, 0, 0);
  animation-name: waveAction, waveEnd;
  animation-iteration-count: $waveCount, 1;
  animation-timing-function: linear, cubic-bezier(0.39, 0.58, 0.57, 1);
  animation-duration: $waveDuration, $waveDuration * 3.5;
  animation-fill-mode: forwards;
  animation-delay: 0s, $waveDuration * $waveCount;
}

.percentage {
  fill: var(--brand-color-neutral, var(--color-neutral-lighter));
  font-weight: 300;
}

.percentageNumber {
  font-weight: 400;
  font-size: 2em;
}
