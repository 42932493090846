.strava {
  --color-base: #fc4c02;
  --color-dark: #ca3d02;
  --color-darker: #b03501;
}

.fitbit {
  --color-base: #4cbfbf;
  --color-dark: #3d9999;
  --color-darker: #2e7373;
}

.manageSettingsButton {
  background-color: var(--color-base);
  color: var(--color-neutral-lighter);
  border: 3px solid var(--color-base);
  width: 100%;

  &:hover {
    background-color: var(--color-dark);
    border-color: var(--color-dark);
  }

  &:focus-visible {
    border-color: var(--color-darker);
  }
}
