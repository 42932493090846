.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-spacing-03);
  flex: 0 1 20%;
}

.dot {
  border: none;
  border-radius: 50%;
  width: var(--size-spacing-03);
  height: var(--size-spacing-03);
  margin: 0 var(--size-spacing-02);
  padding: 0;
  cursor: pointer;
  &:focus-visible {
    outline: none;
  }
}

.dotActive {
  @extend .dot;
  background-color: var(--color-interaction-base);
}
