.animated {
  animation: fading 4.5s infinite;
  border-radius: 5px;
}

@keyframes fading {
  0% {
    background-color: #e6e6e1;
  }

  15% {
    background-color: #c9c9c7
  }

  30% {
    background-color: #e6e6e1;
  }

  45% {
    background-color: #c9c9c7
  }

  60% {
    background-color: #e6e6e1;
  }

  75% {
    background-color: #c9c9c7
  }

  100% {
    background-color: #e6e6e1;
  }
}