.count {
  float: right;
}

.placeholderBar {
  margin: var(--size-spacing-01) 0;
  display: block;
  height: 10px;
}

.error {
  color: var(--color-red-base);
}

.topDonation {
  width: fit-content;
  color: var(--color-interaction-base);
}

.skeletonBox {
  display: flex;
  overflow-x: hidden;
  padding: var(--size-spacing-03) 0;
}

.usernameSkeleton {
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
  margin-top: var(--size-spacing-02);
  padding: 0 var(--size-spacing-03);
}

.donationSkeleton {
  margin-top: var(--size-spacing-05);
}

.separator {
  display: block;
  width: auto;
  height: 1px;
  background-color: var(--color-grey-300);
  margin: var(--size-spacing-04) var(--size-spacing-02) var(--size-spacing-04)
    var(--size-spacing-08);
}

.skeletonContainer {
  margin-top: var(--size-spacing-03);
}

.avatarSkeleton {
  padding-top: var(--size-spacing-02);
}
