@import '../../../styles/breakpoints';

.modal {
  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  text-align: center;
  padding: initial;

  @include device-large {
    padding: var(--size-spacing-05);
  }
}

.appleIconBoost {
  img {
    transform: translateY(-2px);
  }
}
