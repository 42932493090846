@import '../../styles/breakpoints.scss';

.reminder {
  margin: var(--size-spacing-01) 0;
  font-weight: 500;
  background-color: transparent;
  position: relative;

  img {
    position: absolute;
    max-height: var(--size-spacing-05);
    left: var(--size-spacing-03);
    top: var(--size-spacing-03);
    width:var(--size-spacing-05);
    height:var(--size-spacing-05);
    object-fit: contain;
  }

  @include device-large {
    min-width: 400px;
  }
}