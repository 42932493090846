@import '../../styles/breakpoints';

.dialog {
  border: none;
}

.toast {
  position: fixed;
  left: 50%;
  bottom: 0;
  border-radius: 6px;
  transform: translateX(-50%);
  margin: 16px auto;
  width: fit-content;
  padding: var(--size-spacing-05);
  min-width: 18rem;
  display: flex;
  cursor: pointer;
  white-space: initial;
  z-index: 2;

  @include device-large {
    white-space: nowrap;
  }
}

.icon {
  display: flex;
  align-items: center;
  width: 24px;
  margin-right: var(--size-spacing-03);
  flex-shrink: 0;

  svg {
    width: 24px;
    height: 24px;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
}

.success {
  background: var(--color-success-lighter);

  .icon {
    fill: var(--color-success-light);
  }
}

.warning {
  background: var(--color-warning-lighter);

  .icon {
    fill: var(--color-warning-light);
  }
}

.message {
  max-width: 240px;
  line-height: 1.5;

  @include device-large {
    max-width: fit-content;
  }
}

.show {
  animation: toastUp 250ms ease-in running normal forwards;
}

.hide {
  animation: toastDown 175ms ease-in running normal forwards;
}

@keyframes toastUp {
  from {
    transform: translate(-50%, calc(100% + 16px));
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes toastDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, calc(100% + 16px));
  }
}
