.activityList {
  display: flex;
  flex-direction: row;
  gap: var(--size-spacing-02);
  margin-bottom: var(--size-spacing-03);
}

.activityButton {
  composes: btn-unstyled from global;
  border: 1px solid currentColor;
  border-radius: 7px;
  padding: var(--size-spacing-02) var(--size-spacing-03);
  line-height: 1;

  &.active {
    color: var(--color-brand-base);

    &.light {
      color: var(--color-purple-500);
    }
  }
}
