@import '../../styles/breakpoints';

.iconWrapper {
  width: 144px;
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-interaction-lighter);
  border-radius: 50%;
  margin: 40px 0; //not in carepack
}

.content {
  width: 100%;
  @include device-small {
    width: 591px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 284px;
  align-self: center;
  text-align: center;
}

.footer {
  margin-top: 40px; //not in carepack
  display: flex;
  flex-direction: column;
  align-items: center;
}
